import gql from "graphql-tag";

const INTENSIVKURSE_QUERY = gql`
  query Intensivkurse($date: Date!) {
    intensivkurse(
      filters: { startdate: { gt: $date } }
      sort: "startdate:asc" # Сортуємо за зростанням дати
    ) {
      data {
        attributes {
          cursename
          startdate
          enddate
          stillfree
          schedule {
            id
            day
            topic
            time
          }
        }
      }
    }
  }
`;

export default INTENSIVKURSE_QUERY;
