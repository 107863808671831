import gql from "graphql-tag";

const PREISE_QUERY = gql`
  query Preise {
    preise {
      data {
        attributes {
          slug
          title
          header {
            id
            title
            categoryname
            description
            thumb {
              data {
                attributes {
                  url
                }
              }
            }
          }
          pricelists {
            titleb
            titlebe
            pdf1 {
              data {
                attributes {
                  url
                }
              }
            }
            pdf2 {
              data {
                attributes {
                  url
                }
              }
            }
          }
          mainpromo {
            id
            title
            info
            img {
              data {
                attributes {
                  url
                }
              }
            }
          }
          offers {
            id
            title
            info
            img {
              data {
                attributes {
                  url
                }
              }
            }
          }
          promo {
            id
            title
            info
            button
          }
          faq {
            id
            qa {
              id
              question
              answer
            }
          }
        }
      }
    }
  }
`;

export default PREISE_QUERY;
